import { useLocation } from '@reach/router';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import srcLogotype from '../assets/logotype-very-light-blue.svg';
import srcPubBridge from '../assets/newsroom/publisher-bridge.svg';
import srcPubDigiday from '../assets/newsroom/publisher-digiday.png';
import srcPubECNoMikata from '../assets/newsroom/publisher-ec-no-mikata.png';
import srcPubECZine from '../assets/newsroom/publisher-eczine.png';
import Layout from '../components/Layout/Layout';
import type {
  News,
  NewsCategory,
  NewsListByCategory,
} from '../components/News/NewsRow';
import NewsRow from '../components/News/NewsRow';
import { StripType } from '../types';
import styles from './newsroom.module.scss';

interface Props {
  data: {
    contentful: {
      newsCollection: { items: News[] };
      newsCategoryCollection: { items: NewsCategory[] };
      stripCollection: { items: StripType[] };
    };
  };
}

export const query = graphql`
  query NewsPageQuery($language: String!, $dev: Boolean!) {
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      newsCollection(
        order: [date_DESC]
        locale: $language
        where: { slug_exists: true, cover_exists: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          content {
            json
          }
          categoryCollection(limit: 5) {
            items {
              title(locale: $language)
              slug
            }
          }
          merchantCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          publisherCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          slug
          title(locale: $language)
          excerpt(locale: $language)
          date
          cover {
            url
            width
            height
          }
          coverMobile {
            url
            width
            height
          }
          isHero
        }
      }
      newsCategoryCollection(
        locale: $language
        where: { slug_exists: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          slug
          contentfulMetadata {
            tags {
              id
              name
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HEADER_HEIGHT = 64;

const NewsPage = ({ data }: Props) => {
  const location = useLocation();
  const { t, originalPath, i18n } = useI18next();
  const {
    contentful: {
      newsCollection: { items: news },
      newsCategoryCollection: { items: newsCategories },
      stripCollection: { items: strips },
    },
  } = data;

  const defaultCategory = {
    sys: { id: 'latest' },
    slug: 'latest',
    title: t('newsroom-cat-latest'),
  };

  const newsListByCategory = newsCategories.reduce<NewsListByCategory>(
    (accum, category) => {
      accum[category.slug] = news.filter((news) =>
        news.categoryCollection.items.find(
          (item) => item.slug === category.slug
        )
      );

      return accum;
    },
    { latest: news }
  );

  const [hashCatSlug, setHashCatSlug] = useState('');
  const newsRef = useRef<HTMLDivElement>(null);

  const latestNews = news[0];
  const latestHeroNews = news.find((news) => news.isHero);

  const activeCategory =
    newsCategories.find(
      (item) =>
        newsListByCategory[hashCatSlug]?.length > 0 && item.slug === hashCatSlug
    ) || defaultCategory;
  const activeNews = newsListByCategory[activeCategory.slug] || [];

  useEffect(() => {
    setHashCatSlug(location.hash.replace(/^#/, ''));
  }, [location]);

  useLayoutEffect(() => {
    if (activeCategory.slug === hashCatSlug && newsRef.current?.offsetTop) {
      const boundingRect = document.body.getBoundingClientRect();
      const windowScrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const newsOffsetTop = newsRef.current?.offsetTop;

      if (
        newsOffsetTop !== undefined &&
        !(
          newsOffsetTop - windowScrollY > 0 &&
          newsOffsetTop - windowScrollY < windowHeight / 2
        )
      ) {
        window.scrollTo(0, newsOffsetTop - HEADER_HEIGHT);
      }
    }
  }, [activeCategory, hashCatSlug]);

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      className={styles.newsRoot}
      SEOProps={{ title: `Newsroom | ${activeCategory.title}` }}
      strips={strips}
    >
      <header className={styles.hero}>
        <img alt="" src={srcLogotype} className={styles.bg} />
        <h1>{t('newsroom')}</h1>
        <h2>{t('newsroom-subtitle')}</h2>
        <NewsRow
          isHero
          news={latestHeroNews || latestNews}
          className={styles.heroNews}
        />
      </header>
      <section className={styles.newsSection}>
        <div className={styles.media}>
          <h2>{t('newsroom-media-coverage')}</h2>
          <div className={styles.publishers}>
            <div className={styles.publisher}>
              <img src={srcPubDigiday} alt="DIGIDAY" />
            </div>
            <div className={styles.publisher}>
              <img src={srcPubECZine} alt="ECZine" />
            </div>
            <div className={styles.publisher}>
              <img src={srcPubECNoMikata} alt="ECのミカタ" />
            </div>
            <div className={styles.publisher}>
              <img src={srcPubBridge} alt="BRIDGE" />
            </div>
          </div>
        </div>
        <div id="news" className={styles.content} ref={newsRef}>
          <div className={styles.categories}>
            {[defaultCategory, ...newsCategories].map(
              (category: NewsCategory) => {
                return newsListByCategory[category.slug]?.length ? (
                  <a
                    href={`#${category.slug}`}
                    key={category.sys.id}
                    className={cx(
                      activeCategory.slug === category.slug && styles.active
                    )}
                  >
                    {category.title}
                  </a>
                ) : null;
              }
            )}
          </div>
          <nav className={styles.newsList}>
            {activeNews.map((news: News) => {
              return <NewsRow news={news} key={news.sys.id} />;
            })}
          </nav>
        </div>
      </section>
    </Layout>
  );
};

export default NewsPage;
